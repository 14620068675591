<template>
  <div class="todo-container">
    <h1>Runebottle</h1>

    <div class="my-1">
      <input 
      class="form-control"
      v-model="newTask" 
      @keyup.enter="addTask" 
      type="text" 
      placeholder="Add a new task"
    />
    </div>
    

    <button class="btn btn-secondary" @click="addTask">Add Task</button>
    
    <div class="vstack">
      <div v-for="(task, index) in tasks" :key="index">
        {{ task }}
        <button class="btn btn-secondary" @click="removeTask(index)">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newTask: '',
      tasks: []
    }
  },
  methods: {
    addTask() {
      if (this.newTask.trim() !== '') {
        this.tasks.push(this.newTask.trim());
        this.tasks.push("Foo");
        this.newTask = '';
      }
    },
    removeTask(index) {
      this.tasks.splice(index, 1);
    }
  }
}
</script>

<style>
/*
.todo-container {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}

input[type="text"] {
  padding: 10px;
  width: 80%;
  margin-bottom: 10px;
}

button {
  padding: 10px;
  margin-left: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  padding: 10px;
  background: #f3f3f3;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
*/
</style>
